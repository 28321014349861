import React, {Component} from 'react';
import Lightbox from 'react-image-lightbox';
import _ from 'lodash';
import Loading from './Loading'

class SinglePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render() {
    if (!_.isEmpty(this.props.images)) {
      const {photoIndex, isOpen} = this.state;
      const {images} = this.props;
      const gridStyle = (item) => {
        return ({
          backgroundImage: 'url(' + item.src_t + ')'
        })
      };
      const gridItem = images.map((item, i) => {
        return (

          <li onClick={() => this.setState({isOpen: true, photoIndex: i})} style={gridStyle(item)} key={item.id} className="grid-item fade botToTop">
            <span>
              <p>{item.title}</p>
              <p>{item.date}</p>
            </span>
          </li>

        );
      });

      return (
        <ul className="grid">
          {gridItem}
          {isOpen && <Lightbox mainSrc={images[photoIndex].src} nextSrc={images[(photoIndex + 1) % images.length].src} prevSrc={images[(photoIndex + images.length - 1) % images.length].src} onCloseRequest={() => this.setState({isOpen: false})} onMovePrevRequest={() => this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length
            })} onMoveNextRequest={() => this.setState({
              photoIndex: (photoIndex + 1) % images.length
            })} discourageDownloads={true} enableZoom={false}/>
            }
          </ul>
      );
    } else {
      return <Loading />;
    }

  }
}

export default SinglePhoto;
