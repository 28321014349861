import React from 'react';

// import {Link} from 'react-router-dom';


const WorkItem = (props) => {

  //props
  const {post} = props;
  const {width} = props;
  // console.log(props);


  //background image
  const gridStyle = (post) => {
    // const url = cover(item);
    return ({
      backgroundImage: 'url(' + post.content_url + '/' + post.coverimage + ')'
    })
  };


  const logo = () => {
    return width >= 768 ? post.content_url + '/' + post.logo_black : post.content_url + '/' + post.logo_white
  }

  const pickType = (post) =>{
    return post.posttype === "case" ? { type:"Detail", url:post.uid} : { type:"View Live Site", url:post.live_url}
  }

 //  const postContent = () =>{
 //    return(
 //  <div className="row">
 //    <span style={gridStyle(post)}>
 //    <div className="shortDesc">
 //      <p>{post.short_desc}</p>
 //      <p className="credit">{post.credit}</p>
 //    </div>
 //    </span>
 //    <span className="">
 //      <div className="">
 //        <div className="logo-wrapper">
 //          <img alt={post.title} src={logo()}/>
 //        </div>
 //        <hr/>
 //        <div className="info-wrapper">
 //          <div>{post.category}</div>
 //          <div >+{pickType(post).type}</div>
 //        </div>
 //    </div>
 //    </span>
 //    </div>
 //  )};
 //
 // if(post.posttype === "case"){
 //   return(
 //     <Link to={pickType(post).url} key={post.id}>
 //       {postContent()}
 //     </Link>
 //   );
 // }
 //
 // return(
 //   <a href={post.live_url} target="_blank" rel="noopener noreferrer"  key={post.id}>
 //   {postContent()}
 //   </a>
 // );


 return(
   <div className="row" key={post.id}>
     <span style={gridStyle(post)}>
       <div className="fix-wrapper">
         <div className="shortDesc">
           <p>{post.short_desc}</p>
           <p className="credit">{post.credit}</p>
         </div>
       </div>
     </span>
     <span className="">
       <div className="fix-wrapper">
         <div className="">
           <div className="logo-wrapper">
             <img alt={post.title} src={logo()}/>
           </div>
           <hr/>
           <div className="info-wrapper">
             <div className="post-cat">{post.category}</div>
             <div><a className="hover-underline live-site-button" href={post.live_url} target="_blank" rel="noopener noreferrer">+{pickType(post).type}</a></div>
           </div>
         </div>
       </div>
     </span>
     </div>
 );
}

export default WorkItem;
