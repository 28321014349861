import promiseMiddleware from 'redux-promise';
import {createStore, applyMiddleware } from 'redux';
import throttle from 'lodash/throttle'
import { loadState, saveState } from './localStorage'
import reducers from '../reducers';


const configureStore = () => {

  const createStoreWithMiddleware = applyMiddleware(promiseMiddleware)(createStore);
  const persistedState = loadState()
  const store = createStoreWithMiddleware(reducers, persistedState);
  store.subscribe(throttle(() => {
    saveState(store.getState())
  }), 1000);

  return store
}

export default configureStore
