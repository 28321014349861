import React from 'react';
import {Link} from 'react-router-dom'
import logo from '../images/logo.svg'
import Headroom from 'react-headroom'
// import Overdrive from 'react-overdrive';


const Nav = (props) => {
  return(
    <Headroom disableInlineStyles={true}>
      <nav className=" fade">
        <div className="container nav-wrapper">
          <span className="nav-title topToBot">
            <h4>{props.title}</h4>
          </span>
          <span className="nav-logo">
            <Link to="/" >
            {/* <Overdrive id="raysu" animationDelay={0.5} duration={500}> */}
              <img  src={logo} alt="ray su" id="logo" />
            {/* </Overdrive> */}
            </Link>
          </span>
          <div className="nav-ham">
            <Link className="hamburger" to="/">
              <div></div>
              <div></div>
              <div></div>
            </Link>
          </div>
        </div>
      </nav>
    </Headroom>
      );
}

export default Nav
