import React, { Component } from "react";
import { connect } from "react-redux";
import sizeMe from "react-sizeme";
import { Helmet } from "react-helmet";
import { fetchPosts } from "../actions";
import Nav from "./Nav";
import WorkItem from "./WorkItem";
import _ from "lodash";
import Loading from "./Loading";

class Work extends Component {
  componentWillMount() {
    this.props.fetchPosts();
  }

  render() {
    //window width for logo
    const { width } = this.props.size;

    //state
    const { posts } = this.props;

    //create post grid-item
    const post = () => {
      if (_.isEmpty(posts)) {
        return <Loading />;
      }
      return posts.map((item) => {
        return <WorkItem post={item} key={item.id} width={width} />;
      });
    };

    return (
      <div>
        <Helmet>
          <title>Work | Ray Su | Whatever.</title>
        </Helmet>
        <Nav title={"WORK"} />
        <div className="container page-section  botToTopWork work">
          {post()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { posts: state.posts };
};

export default connect(mapStateToProps, { fetchPosts })(
  sizeMe({ refreshRate: 60 })(Work)
);
