import { combineReducers } from 'redux';
import PhotosReducer from './PhotosReducer';
import PostsReducer from './PostsReducer';
import SinglePostReducer from './SinglePostReducer';

const rootReducer = combineReducers({
  photos:PhotosReducer,
  posts:PostsReducer,
  post:SinglePostReducer
});

export default rootReducer;
