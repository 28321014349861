import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import Nav from './Nav';
import logoPx from '../images/500px.svg'
import logoIg from '../images/ig.svg'
import logoIn from '../images/in.svg'
import logoEm from '../images/em.svg'
import logoGit from '../images/github.svg'


class About extends Component{

  shouldComponentUpdate() {
    return false;
  }
  render(){
  return (
    <div>
      <Helmet>
        <title>About | Ray Su</title>
      </Helmet>
      <Nav title={'About'}/>
      <div className="container outter-mt fade">
        <div className="text-box">
          <h1>I’m Ray Su</h1>
          <h4>A Front End Developer<br/>and UX/UI Designer</h4>
          <p>I enjoy every aspect of building websites, from UX design to development. My primary focus presently is on development but I love to explore new UX and visual trends to strengthen my design sensation. I am constantly learning new techniques and frameworks in both Front End and back-end to prepare myself for becoming a better developer. In my leisure time, I like taking pictures and playing music.
          </p>
        </div>
        <div className="text-box skill">
          <h2>Skills</h2>
          <p><b>Front End</b><br/>React, Redux, Javascript, ES6, CSS/Sass,  jQuery, Bootstrap, HTML</p>
          <p><b>CMS / E-Commerce</b>
            <br/>WordPress, Kirby, OpenCart, Shopify</p>
          <p><b>Cloud / Hosting</b><br/>AWS: EC2/S3/Route 53, Apache,  Firebase, Express</p>
          {/* <p><b>Back-end</b><br/>Express</p> */}
          <p><b>Tools</b><br/>Git, Gulp, Atom, Photoshop, Illustrator</p>
        </div>
        <div className="text-box">
          <div className="about-social">
         <span><a href="mailto:raysu.info@gmail.com"><img src={logoEm} alt="raysu.info@gmail.com"/></a></span>
          <span><a target="_blank" rel="noopener noreferrer"  href="https://github.com/ray-su"><img src={logoGit} alt="GitHub"/></a></span>
          <span><a target="_blank" rel="noopener noreferrer"  href="https://www.linkedin.com/in/rayjhsu/"><img src={logoIn} alt="LinkedIn"/></a></span>
          <span><a target="_blank" rel="noopener noreferrer"  href="https://www.instagram.com/ray_s_/"><img src={logoIg} alt="Instagram"/></a></span>
          <span><a target="_blank" rel="noopener noreferrer"  href="https://500px.com/raysu"><img src={logoPx} alt="Instagram"/></a></span>
        </div>
        </div>
        <div className="text-box">
          <h4>About this Website</h4>
          <p>This site has been designed and built from scratch by myself. It's built with React and Redux.  The work page is CMS driven, I've used Kirby, a flat-file based CMS, to provide the data to React with RESTful APIs. For the photos page, I've used Flickr, for which the photo data is fetched from Flickr's API. Lastly, the CSS is written with a mobile first approach in Sass.
          </p>
        </div>


        <div>

        </div>
      </div>
    </div>
  )};
}

export default About;
