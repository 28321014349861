import React, { useEffect } from "react";
import logo from "../images/logo.svg";
// import logoPx from '../images/500px.svg'
import logoIg from "../images/ig.svg";
import logoIn from "../images/in.svg";
import logoEm from "../images/em.svg";
import logoGit from "../images/github.svg";
import { Link } from "react-router-dom";
import axios from "axios";

const Index = () => {
  // Make sure  to request a session with depth-sensing enabled
  // const session = () => {
  //   let logicalProcessors = window.navigator.hardwareConcurrency;
  //   console.log(logicalProcessors);

  //   if ("geolocation" in navigator) {
  //     /* geolocation is available */
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       console.log("geo", position.coords.latitude, position.coords.longitude);
  //     });
  //   }
  //   if ("connection" in navigator) {
  //     const { connection } = navigator;
  //     console.log("connection", connection);
  //   }
  //   if ("language" in navigator) {
  //     const { language } = navigator;
  //     console.log("language", language);
  //   }
  // };

  // const asyncShit = async () => {
  //   try {
  //     const twitter = await axios.get(
  //       "https://accounts.google.com/ServiceLogin?passive=true&continue=https%3A%2F%2Fwww.youtube.com%2Ffavicon.ico&uilel=3&hl=en&service=youtube"
  //     );
  //     console.log("twitter", twitter.data);
  //   } catch (e) {
  //     console.log("error", e.response);
  //   }
  // };

  // useEffect(() => {
  //   session();
  //   asyncShit();
  // }, []);

  return (
    <div className="index-outter-wrap">
      <div className="fullscreen-border fade"></div>
      <div className="section">
        <div className="index-logo fade">
          <img id="logo" src={logo} alt="Ray Su" />
        </div>
      </div>

      <div className="container">
        <div className="section">
          <div className="text-box fade">
            <h2 style={{ textAlign: "center" }}>Under Construction</h2>
            {/* <p style={{ textAlign: "center" }}>Coming Soon</p> */}
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="section">
          <div className="text-box fade">
            <h2>About Me</h2>
            <p>Whatever.</p>
          </div>
        </div>
        <div c lassName="section">
          <div className="text-box fade">
            <h2>About You</h2>
          </div>
          <div
            className="section"
            dangerouslySetInnerHTML={{
              __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7065528981027834"
     crossorigin="anonymous"></script>
<!-- square -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-7065528981027834"
     data-ad-slot="8113970453"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
            }}
          ></div>
          <div
            className="section"
            id="586874427"
            dangerouslySetInnerHTML={{
              __html: ` <script type="text/javascript">
                try {
                    window._mNHandle.queue.push(function (){
                        window._mNDetails.loadTag("586874427", "728x90", "586874427");
                    });
                }
                catch (error) {}
            </script>`,
            }}
          ></div>
        </div>
      </div> */}
    </div>
  );
};

export default Index;
