import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
// import createHistory from 'history/createBrowserHistory';
// import ReactGA from 'react-ga';

import configureStore from "./config/configureStore";
import Index from "./components/Index";
import About from "./components/About";
import Photos from "./components/Photos";
import Work from "./components/Work";
// import WorkShow from './components/WorkShow'

import "./style/style.sass";
import registerServiceWorker from "./registerServiceWorker";
//
// ReactGA.initialize('UA-74733407-1');
//
// const history = createHistory()
// history.listen((location, action) => {
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <Helmet>
          <title>Ray Su | Whatever.</title>
        </Helmet>
        <Switch>
          {/* <Route path="/work/:id" component={WorkShow}/> */}
          {/* <Route path="/work" component={Work}/> */}
          {/* <Route path="/photos" component={Photos}/> */}
          {/* <Route path="/about" component={About}/> */}
          <Route path="/" component={Index} />
        </Switch>
      </div>
    </BrowserRouter>
  </Provider>,
  document.querySelector("#view")
);
registerServiceWorker();
