import axios from 'axios';
import {FETCH_PHOTOS} from './types';
import {FETCH_POSTS} from './types';
// import {SINGLE_POST} from './types';
import _ from 'lodash';

//
// console.log(behence)


export const fetchPhotos = () => {
    // console.log('fetchPhoto fired');
    const request = axios.get('https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=e2ac2f8fa42145dd458215975376417e&photoset_id=72157687192567195&user_id=53375040%40N03&extras=url_c%2Curl_h%2Cdate_taken&format=json&nojsoncallback=1')
    .catch(err => {
      console.log('this is err', err);
    })
    .then(
      (response)=>{
        let finalProduct =[];
        response.data.photoset.photo.map(item => {
          let obj = {};
          obj['src_t'] = _.get(item, 'url_c');
          obj['src'] = _.get(item, 'url_h');
          obj['id'] = _.get(item, 'id');
          obj['title'] = _.get(item, 'title');
          obj['date'] = _.get(item, 'datetaken').split(' ')[0];
          return finalProduct.push(obj);
        });
        // saveState(finalProduct);
        // console.log(response)
        return finalProduct;
      }
    );

    return{
      type: FETCH_PHOTOS,
      payload: request
    };
}


export const fetchPosts = () => {
  const posts = axios.get('https://raysu.ca/cm/xyz/tree/blog')
  .catch(err => {
    console.log('this is err', err);
  })
  .then(
    (response)=>{
    return response.data.children.reverse();
  });
  return{
    type: FETCH_POSTS,
    payload: posts
  };
}

// export const fetchSinglePost = (id) => {
//   const post = axios.get(`http://localhost:8888/api/tree/${id}`);
//   return{
//     type: SINGLE_POST,
//     payload: post
//   };
// }
