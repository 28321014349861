import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { fetchPhotos } from "../actions";
import {
  localStorageKey,
  storeDate,
  localStorageDateKey,
} from "../config/localStorage";
import SinglePhoto from "./SinglePhoto";
import Nav from "./Nav";

class Photos extends Component {
  componentWillMount() {
    if (
      !localStorage.getItem(localStorageKey) ||
      localStorage.getItem(localStorageKey) === "null" ||
      _.isEmpty(JSON.parse(localStorage.getItem(localStorageKey)).photos) ||
      localStorage.getItem(localStorageDateKey) !== storeDate
    ) {
      this.props.fetchPhotos();
    }
  }

  render() {
    const { photos } = this.props;

    return (
      <div>
        <Helmet>
          <title>Photos | Ray Su | Whatever.</title>
        </Helmet>

        <Nav title={"PHOTOS"} />

        <div className="container page-section fade photos">
          <SinglePhoto images={photos} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { photos: state.photos };
};

export default connect(mapStateToProps, { fetchPhotos })(Photos);
