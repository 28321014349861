import React from 'react';
import logoIg from '../images/ig.svg'
import logoIn from '../images/in.svg'
import logoEm from '../images/em.svg'
import logoGit from '../images/github.svg'

const Loading = () =>{
  return(
    <div className="error-wrapper container">
      <h2 className="error-msg">Loading...</h2>
      <p>If you see this meesage for more than 10 sec,</p>
      <p>This page is probably crashed</p>
      <div className="index-social fade">
        <span><a href="mailto:raysu.info@gmail.com"><img src={logoEm} alt="raysu.info@gmail.com"/></a></span>
        <span><a target="_blank" rel="noopener noreferrer"  href="https://github.com/ray-su"><img src={logoGit} alt="GitHub"/></a></span>
        <span><a target="_blank" rel="noopener noreferrer"  href="https://www.linkedin.com/in/rayjhsu/"><img src={logoIn} alt="LinkedIn"/></a></span>
        <span><a target="_blank" rel="noopener noreferrer"  href="https://www.instagram.com/ray_s_/"><img src={logoIg} alt="Instagram"/></a></span>

      </div>
    </div>
  );
}
export default Loading;
